<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Historial Clínico</h4>
					</template>
					<template v-slot:body>
						<DataTable
							v-if="history"
							:data="history"
							:columns="columns"
							:fields-search="[
								'diagnoses',
								'weight',
								'height',
								'systolic_pressure',
								'diastolic_pressure',
							]"
						>
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ rowIndex + 1 }}</th>
									<td>
										{{ row.user.first_last_name }} {{ row.user.second_last_name }},
										{{ row.user.name }}
									</td>
									<td>
										{{ row.diagnoses }}
									</td>
									<td>
										{{ row.weight }}
									</td>
									<td>
										{{ row.height }}
									</td>
									<td>
										{{ row.systolic_pressure }}
									</td>
									<td>
										{{ row.diastolic_pressure }}
									</td>
									<td>
										{{ row.created_at }}
									</td>
									<td>
										<b-button-group>
											<b-button
												v-if="role != 'Paciente'"
												v-user-can="'leer pacientes'"
												variant="warning"
												size="sm"
												@click="showModalEdit(row)"
												v-b-tooltip.hover
												title="Editar"
											>
												<i class="ri-ball-pen-fill m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="secondary"
												size="sm"
												@click="showModal(row)"
											>
												<i class="ri-eye-fill m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>

		<b-modal id="modal-data" title="Historial Clínico" hide-footer>
			<ul>
				<li><b>Ocupación:</b> {{ data.occupation }}</li>
				<li><b>Diagnósticos:</b> {{ data.diagnoses }}</li>
				<li><b>Tipo de Sangre:</b> {{ data.blood_type }}</li>
				<li><b>Antecedentes Familiares:</b> {{ data.family_records }}</li>
				<li><b>Problemas de salud pasados y actuales:</b> {{ data.health_problems }}</li>
				<li>
					<b>Condiciones crónicas o enfermedades:</b>
					{{ data.chronic_conditions_diseases }}
				</li>
				<li>
					<b>Lesiones previas o cirugías:</b> {{ data.previous_injuries_surgeries }}
				</li>
				<li><b>Alergias conocidas:</b> {{ data.known_allergies }}</li>
				<li>
					<b>Medicamentos que el paciente esté tomando actualmente:</b>
					{{ data.medications_currently_taking }}
				</li>
				<li><b>Terapias o tratamientos de fisioterapia:</b> {{ data.therapies }}</li>
				<li><b>Hábitos:</b> {{ data?.habits.length > 0 ? data?.habits.join() : "-" }}</li>
				<li>
					<b>Información sobre estilo de vida y actividad fisica:</b> {{ data.lifestyle }}
				</li>
				<li><b>Hábitos alimentarios relevantes:</b> {{ data.relevant_habits }}</li>
				<li><b>Hábitos de sueño:</b> {{ data.sleep_habits }}</li>
				<li><b>Condición de salud mental:</b> {{ data.mental_health_condition }}</li>
				<li><b>Peso (kg):</b> {{ data.weight }}</li>
				<li><b>Estatura:</b> {{ data.height }}</li>
				<li><b>Presión Arterial Sistólica:</b> {{ data.systolic_pressure }}</li>
				<li><b>Presión Arterial Diastólica:</b> {{ data.diastolic_pressure }}</li>
			</ul>
		</b-modal>

		<b-modal
			id="modal-edit"
			title="Editar Historial Clínico"
			hide-footer
			size="lg"
			no-close-on-backdrop
		>
			<ValidationObserver ref="form" v-slot="{ handleSubmit }">
				<b-form @submit.stop.prevent="handleSubmit(onSubmitExtraData)">
					<b-row>
						<b-form-group class="col-md-6" label="Ocupación:">
							<ValidationProvider name="Ocupación" rules="required" v-slot="{ errors }">
								<b-form-input
									v-model="data.occupation"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Diagnósticos:">
							<ValidationProvider
								name="Diagnósticos"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.diagnoses"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Tipo de Sangre:">
							<ValidationProvider
								name="Tipo de Sangre"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-select
									v-model="data.blood_type"
									plain
									:options="['A+', 'O+', 'B+', 'AB+', 'A-', 'O-', 'B-', 'AB-']"
									id="blood_type"
								>
								</b-form-select>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Antecedentes Familiares:">
							<ValidationProvider
								name="Antecedentes Familiares"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.family_records"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<p>Antecedentes Personales</p>
						</div>
						<b-form-group class="col-md-4" label="Problemas de salud pasados y actuales:">
							<ValidationProvider
								name="Problemas de salud pasados y actuales"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.health_problems"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Condiciones crónicas o enfermedades:">
							<ValidationProvider
								name="Condiciones crónicas o enfermedades"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.chronic_conditions_diseases"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Lesiones previas o cirugías:">
							<ValidationProvider
								name="Lesiones previas o cirugías"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.previous_injuries_surgeries"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Alergias conocidas:">
							<ValidationProvider
								name="Alergias conocidas"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.known_allergies"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group
							class="col-md-8"
							label="Medicamentos que el paciente esté tomando actualmente:"
						>
							<ValidationProvider
								name="Medicamentos que el paciente esté tomando actualmente"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.medications_currently_taking"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<hr />
						</div>
						<b-form-group
							class="col-md-6"
							label="Terapias o tratamientos de fisioterapia:"
						>
							<ValidationProvider
								name="Terapias o tratamientos de fisioterapia"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.therapies"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos:">
							<ValidationProvider name="Hábitos" rules="required" v-slot="{ errors }">
								<v-select
									:options="[
										'ALCOHOL',
										'CIGARRILLOS',
										'EJERCICIOS',
										'BUENA ALIMENTACIÓN',
										'MALA ALIMENTACIÓN',
										'CAFEINA',
									]"
									v-model="data.habits"
									:class="errors.length > 0 ? ' is-invalid' : ''"
									multiple
								/>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group
							class="col-md-6"
							label="Información sobre estilo de vida y actividad fisica:"
						>
							<ValidationProvider
								name="Información sobre estilo de vida y actividad fisica"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.lifestyle"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos alimentarios relevantes:">
							<ValidationProvider
								name="Hábitos alimentarios relevantes"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.relevant_habits"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos de sueño:">
							<ValidationProvider
								name="Hábitos de sueño"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.sleep_habits"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Condición de salud mental:">
							<ValidationProvider
								name="Condición de salud mental"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.mental_health_condition"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Peso (kg):">
							<ValidationProvider name="Peso" rules="required" v-slot="{ errors }">
								<b-form-input
									v-model="data.weight"
									type="number"
									step=".01"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Estatura (cm.):">
							<ValidationProvider name="Estatura" rules="required" v-slot="{ errors }">
								<b-form-input
									v-model="data.height"
									type="number"
									step=".01"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Presión Arterial Sistólica:">
							<ValidationProvider
								name="Presión Arterial"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.systolic_pressure"
									type="number"
									step=".01"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Presión Arterial Diastólica:">
							<ValidationProvider
								name="Presión Arterial"
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.diastolic_pressure"
									type="number"
									step=".01"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
					</b-row>
					<b-button type="submit" variant="primary" class="mr-2"> Guardar </b-button>
					<b-button type="reset" variant="none" class="iq-bg-danger"> Cancelar </b-button>
				</b-form>
			</ValidationObserver>
		</b-modal>
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import Swal from "sweetalert2";

export default {
	components: {
		DataTable,
	},
	data() {
		return {
			role: null,
			data: {
				occupation: null,
				diagnoses: null,
				blood_type: null,
				family_records: null,
				health_problems: null,
				chronic_conditions_diseases: null,
				previous_injuries_surgeries: null,
				known_allergies: null,
				medications_currently_taking: null,
				therapies: null,
				habits: [],
				lifestyle: null,
				relevant_habits: null,
				sleep_habits: null,
				mental_health_condition: null,
				weight: null,
				height: null,
				systolic_pressure: null,
				diastolic_pressure: null,
			},
			type: "add",
			history: [],
			columns: [
				{
					name: "Paciente",
					field: "name",
					sort: "",
				},
				{
					name: "Diagnóstico",
					field: "diagnoses",
					sort: "",
				},
				{
					name: "Peso (kg)",
					field: "weight",
					sort: "",
				},
				{
					name: "Altura",
					field: "height",
					sort: "",
				},
				{
					name: "Presión Sistólica",
					field: "systolic_pressure",
					sort: "",
				},
				{
					name: "Presión Diastólica",
					field: "diastolic_pressure",
					sort: "",
				},
				{
					name: "Fecha",
					field: "created_at",
					sort: "",
				},
				{
					name: "Acciones",
					field: "actions",
					sort: "",
				},
			],
			lastId: null,
		};
	},
	mounted() {
		xray.index();
		this.getData();
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
	},
	methods: {
		async getData() {
			try {
				const { data } = await api.get("/medical-history");
				this.history = data.data;
				this.lastId = this.history[0].id;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		showModal(data) {
			this.data = data;
			this.$bvModal.show("modal-data");
		},
		showModalEdit(data) {
			this.data = data;
			this.$bvModal.show("modal-edit");
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/medical-history/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
		async onSubmitExtraData() {
			const url = `/patients/${this.data.patient.id}/extra`;
			try {
				await api.put(url, this.data);
				await this.getData();
				this.$bvModal.hide("modal-edit");
			} catch (error) {
				console.log("error");
				console.log(error);
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
	},
};
</script>
